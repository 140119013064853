import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  deactivateMenu,
  deactivateAccountMenu,
  deactivateSubmenu,
} from "app-store-v2/app/actions"

const OverlayStyle = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: ${(props) => (props.showOverlay ? "block" : "none")};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  ${(props) => props.theme.mediaQueries.widescreen`
    display: none;
  `}
`

const InternalOverLay = ({ closeMenu, showOverlay }) => (
  <OverlayStyle
    onClick={() => {
      closeMenu()
    }}
    showOverlay={showOverlay}
  />
)

InternalOverLay.propTypes = {
  closeMenu: PropTypes.func,
  showOverlay: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const showAccountMenu = state.menu.showAccountMenu
  const showMenu = state.menu.showMenu
  return {
    showOverlay: showAccountMenu || showMenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeMenu: () => {
      const pageBody = document.body
      dispatch(deactivateMenu())
      dispatch(deactivateAccountMenu())
      dispatch(deactivateSubmenu())
      pageBody.classList.remove("nav-open")
    },
  }
}

const OverLay = connect(mapStateToProps, mapDispatchToProps)(InternalOverLay)
export default OverLay
