import { Box, Button } from "@jewlr/storybook/core"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const Nav = styled(Box).attrs((props) => ({
  m: 0,
  p: "10px 12px",
  ...props,
}))``
export const NavList = styled(Box).attrs((props) => ({
  as: "ul",
  ...props,
}))`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const NavItem = styled(Box).attrs((props) => ({
  as: "li",
  width: "100%",
  ...props,
}))``
export const NavButton = styled(Button).attrs((props) => ({
  alignItems: "center",
  color: "black",
  display: props.block ? "block" : "flex",
  justifyContent: "space-between",
  padding: "10px 8px",
  variant: "text",
  ...props,
}))`
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  &:active {
    opacity: 0.6;
  }
`
export const NavLink = styled(Box).attrs((props) => ({
  as: Link,
  color: "black",
  ...props,
}))`
  display: block;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 10px 8px;
  text-transform: uppercase;
  width: 100%;
  &:active {
    opacity: 0.6;
  }
`
export const MobileMenuStyled = styled(motion.div).attrs({
  animate: "show",
  exit: "hide",
  initial: "hide",
  transition: {
    type: "linear",
  },
  variants: {
    hide: {
      right: "-100%",
      transition: { duration: 0.5 },
    },
    show: {
      right: 0,
      transition: { duration: 0.5 },
    },
  },
})`
  -webkit-overflow-scrolling: touch;
  background-color: ${(props) => props.theme.colors.white};

  height: 100%;
  max-width: 375px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
  padding-bottom: 55px;
  position: fixed;
  width: 75%;
  z-index: ${(props) => props.theme.zIndices.submenus};
`
