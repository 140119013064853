import { useAriaHidden } from "@jewlr/storybook/helpers"
import loadable from "@loadable/component"
import { FocusScope } from "@react-aria/focus"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import ScrollLock, {
  TouchScrollable,
} from "react-scrolllock-with-horizontal-scroll"
import styled from "styled-components"

import withHeader from "areas/header/hoc/with-header"
import { retry } from "helpers/application"
import { useNavigation } from "helpers/use-navigation"

import { Nav, NavList, MenuTransition, MobileMenuStyled } from "../components"
import OverLay from "../overlay"

const MobileNav = loadable(() => retry(() => import("./mobile-nav")))

const Menu = styled(motion.div).attrs(() => ({
  initial: "hides",
  variants: {
    hides: {
      transitionEnd: { position: "fixed", width: "75%" },
    },
    shows: {
      delay: 0.32,
      transitionEnd: { position: "static", width: "auto" },
    },
  },
}))`
  max-width: 375px;
  padding-bottom: 120px;
`

const MobileMenuContent = ({ showMenu, showSubmenu, toggleMenu }) => {
  const menuRef = useRef()
  const keyboardProps = useNavigation({
    onEscape: () => toggleMenu(showMenu),
  })

  useAriaHidden({ ref: menuRef })

  return (
    <MobileMenuStyled
      id="mobile-menu"
      ref={menuRef}
      showMenu={showMenu}
      {...keyboardProps}
    >
      <Menu animate={showSubmenu ? "hides" : "shows"}>
        <Nav as="nav">
          <NavList aria-label="Main Navigation" role="menu">
            <MobileNav />
          </NavList>
        </Nav>
      </Menu>
    </MobileMenuStyled>
  )
}

MobileMenuContent.propTypes = {
  showMenu: PropTypes.bool,
  showSubmenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

const InternalMobileMenu = ({ showMenu, showSubmenu, toggleMenu }) => (
  <AnimatePresence>
    {showMenu && (
      <MenuTransition>
        <FocusScope autoFocus contain restoreFocus>
          <ScrollLock isActive={showMenu}>
            <TouchScrollable>
              <MobileMenuContent
                showMenu={showMenu}
                showSubmenu={showSubmenu}
                toggleMenu={toggleMenu}
              />
            </TouchScrollable>
          </ScrollLock>
        </FocusScope>
        <OverLay />
      </MenuTransition>
    )}
  </AnimatePresence>
)

InternalMobileMenu.propTypes = {
  showMenu: PropTypes.bool,
  showSubmenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default withHeader(InternalMobileMenu)
